import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ProjectsService } from '../../shared/generated';
import { SnackbarActions } from '../snackbar/snackbar.actions';
import { ProjectsActions } from './projects.actions';

@Injectable()
export class ProjectsEffects {
  private actions$: Actions = inject(Actions);

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.LoadProjects),
      switchMap(() =>
        this.service.getProjects().pipe(
          map((projects) =>
            ProjectsActions.LoadProjectsSuccess({ payload: projects }),
          ),
          catchError(() => [
            ProjectsActions.LoadProjectsError(),
            SnackbarActions.ShowErrorSnackbar({
              messageKey:
                'feasibility_study.extension_examination.load_projects_error',
            }),
          ]),
        ),
      ),
    ),
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.DeleteProject),
      map((a) => a.payload),
      switchMap((id) =>
        this.service.deleteProject(id).pipe(
          mergeMap(() => [
            ProjectsActions.DeleteProjectSuccess({ payload: id }),
            SnackbarActions.ShowSuccessSnackbar({
              messageKey: 'feasibility_study.snackbar.delete_project_success',
            }),
          ]),
          catchError(() => [
            ProjectsActions.DeleteProjectError(),
            SnackbarActions.ShowErrorSnackbar({
              messageKey: 'feasibility_study.snackbar.delete_project_error',
            }),
          ]),
        ),
      ),
    ),
  );

  lockProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.LockProject),
      map((a) => a.payload),
      switchMap((id) =>
        this.service.lockProject(id).pipe(
          mergeMap((project) => [
            ProjectsActions.SetProjectLock({
              payload: { id: id, isLocked: project.is_locked },
            }),
            SnackbarActions.ShowSuccessSnackbar({
              messageKey: 'feasibility_study.snackbar.lock_project_success',
            }),
          ]),
          catchError(() => [
            SnackbarActions.ShowErrorSnackbar({
              messageKey: 'feasibility_study.snackbar.lock_project_error',
            }),
          ]),
        ),
      ),
    ),
  );

  unlockProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.UnlockProject),
      map((a) => a.payload),
      switchMap((id) =>
        this.service.unlockProject(id).pipe(
          mergeMap((project) => [
            ProjectsActions.SetProjectLock({
              payload: { id: id, isLocked: project.is_locked },
            }),
            SnackbarActions.ShowSuccessSnackbar({
              messageKey: 'feasibility_study.snackbar.unlock_project_success',
            }),
          ]),
          catchError(() => [
            SnackbarActions.ShowErrorSnackbar({
              messageKey: 'feasibility_study.snackbar.unlock_project_error',
            }),
          ]),
        ),
      ),
    ),
  );

  constructor(private service: ProjectsService) {}
}
