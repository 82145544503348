import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarActions } from '@design-system/feature/snackbar';
import { UserService } from '@features/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { FeasibilityStudyState } from '../..';
import { AutoStudyService, ProjectsService } from '../../../shared/generated';
import { SettingsActions } from '../../settings';
import { TailLiftActions } from '../../tail-lift';
import { TruckActions } from '../../truck';
import { CurrentProjectActions } from './current-project.actions';
import { CurrentProjectSelectors } from './current-project.selectors';

@Injectable()
export class CurrentProjectEffects {
  private publicUserId: string;
  private actions$: Actions = inject(Actions);
  private store: Store<FeasibilityStudyState> = inject(Store);

  saveProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentProjectActions.SaveProject),
      map((props) => props.saveAs),
      withLatestFrom(this.store.select(CurrentProjectSelectors.getProjectData)),
      switchMap(([saveAs, data]) => {
        if (saveAs) data = { ...data, id: 0 };
        return this.projectService.saveDraft(data).pipe(
          mergeMap((response) => [
            CurrentProjectActions.SaveProjectSuccess({
              id: response.id,
              isLocked: response.is_locked,
            }),
            SnackbarActions.ShowSuccessSnackbar({
              messageKey:
                'feasibility_study.extension_examination.save_project_success',
            }),
          ]),
          catchError(() => [
            CurrentProjectActions.SaveProjectError(),
            SnackbarActions.ShowErrorSnackbar({
              messageKey:
                'feasibility_study.extension_examination.save_project_error',
            }),
          ]),
        );
      }),
    ),
  );

  sendRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentProjectActions.SendRequest),
      withLatestFrom(
        this.store.select(CurrentProjectSelectors.getAutostudyData),
        inject(UserService).isAuthorized$,
      ),
      switchMap(([, data, isAuthorized]) =>
        (isAuthorized
          ? this.autoStudyService.createCalculation(data)
          : this.autoStudyService.publicCreateCalculation(this.publicUserId, {
              ...data,
              language: this.translateService.getBrowserLang(),
            })
        ).pipe(
          map((resp) =>
            CurrentProjectActions.SendRequestSuccess({ payload: resp }),
          ),
          catchError(() => [
            CurrentProjectActions.SendRequestError(),
            SnackbarActions.ShowErrorSnackbar({
              messageKey:
                'feasibility_study.extension_examination.calculation_request_error',
            }),
          ]),
        ),
      ),
    ),
  );

  showLoadingSnackbar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentProjectActions.SendRequest),
      map(() =>
        SnackbarActions.ShowInfoSnackbar({
          messageKey: 'feasibility_study.snackbar.calculation_loading_info',
        }),
      ),
    ),
  );

  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentProjectActions.LoadProject),
      map((a) => a.id),
      switchMap((id) =>
        this.projectService.getProject(id).pipe(
          mergeMap((project) => {
            const actions: Action[] = [
              CurrentProjectActions.LoadProjectSuccess({ payload: project }),
              TruckActions.SetSelectedTruck({ payload: project.truck }),
            ];

            if (project.truck) {
              actions.push(
                TruckActions.LoadTruckDetailsSuccess({
                  payload: project.truck,
                }),
                SettingsActions.SetbuildUpStart({
                  payload: project.truck.front_axle_to_box_body,
                }),
              );

              if (!project.truck_adaptions)
                actions.push(
                  SettingsActions.SetTruckAdaptionDimensions({
                    payload: project.truck,
                  }),
                );
            }

            return actions;
          }),
          catchError(() => [
            CurrentProjectActions.LoadProjectError(),
            SnackbarActions.ShowErrorSnackbar({
              messageKey:
                'feasibility_study.extension_examination.load_project_error',
            }),
          ]),
        ),
      ),
    ),
  );

  resetProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentProjectActions.Reset),
      mergeMap(() => [
        SettingsActions.Reset(),
        TailLiftActions.Reset(),
        TruckActions.Reset(),
      ]),
    ),
  );

  updateIdQueryParam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CurrentProjectActions.SaveProjectSuccess),
        tap((a) => {
          this.router.navigate([], { queryParams: { id: a.id } });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private projectService: ProjectsService,
    private autoStudyService: AutoStudyService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.publicUserId = this.generateUUID();
  }

  private generateUUID(): string {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, () => {
      const r = Math.floor(Math.random() * 16);
      return r.toString(16);
    });
  }
}
