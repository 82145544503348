import { createSelector } from '@ngrx/store';
import { FeasibilityStudyState } from '..';
import { TailLiftState } from './tail-lift.reducer';

export namespace TailLiftSelectors {
  export const tailLliftState = (state: FeasibilityStudyState) =>
    state.tailLift;

  export const getTailLifts = createSelector(
    tailLliftState,
    (state: TailLiftState) => state.tailLliftsResult,
  );
  export const getTailLiftsLoading = createSelector(
    tailLliftState,
    (state: TailLiftState) => state.tailLliftsResultLoading,
  );
  export const getTailLiftsLoadingError = createSelector(
    tailLliftState,
    (state: TailLiftState) => state.tailLliftsResultLoadingError,
  );
  export const getSelectedTailLift = createSelector(
    tailLliftState,
    (state) =>
      state.tailLliftsResult?.filter(
        (t) => t.id === state.selectedTailLiftId,
      )[0],
  );
  export const getSelectedTailLiftId = createSelector(
    tailLliftState,
    (state) => state.selectedTailLiftId,
  );
}
