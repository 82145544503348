import {
  BuildUpDimensions,
  SettingDimensions,
  TruckDimensions,
} from '../models/models';

// https://schneidenbach.gitbooks.io/typescript-cookbook/content/nameof-operator.html
const nameof = <T>(name: keyof T) => name as string;

export class Constants {
  public static get DK_DIMENSION() {
    return nameof<SettingDimensions>('dkDimension');
  }
  public static get D_DIMENSION() {
    return nameof<SettingDimensions>('dDimension');
  }
  public static get H_DIMENSION_LOADED() {
    return nameof<SettingDimensions>('hDimensionLoaded');
  }
  public static get H_DIMENSION_UNLOADED() {
    return nameof<SettingDimensions>('hDimensionUnLoaded');
  }
  public static get U_DIMENSION() {
    return nameof<SettingDimensions>('uDimension');
  }
  public static get N_DIMENSION() {
    return nameof<SettingDimensions>('nDimension');
  }
  public static get V_DIMENSION() {
    return nameof<SettingDimensions>('vDimension');
  }
  public static get X_DIMENSION() {
    return nameof<SettingDimensions>('xDimension');
  }
  public static get M_DIMENSION() {
    return nameof<SettingDimensions>('mDimension');
  }
  public static get C_DIMENSION() {
    return nameof<SettingDimensions>('cDimension');
  }
  public static get C1_DIMENSION() {
    return nameof<SettingDimensions>('c1Dimension');
  }
  public static get C2_DIMENSION() {
    return nameof<SettingDimensions>('c2Dimension');
  }
  public static get C3_DIMENSION() {
    return nameof<SettingDimensions>('c3Dimension');
  }
  public static get R_DIMENSION() {
    return nameof<SettingDimensions>('rDimension');
  }
  public static get Y_DIMENSION() {
    return nameof<SettingDimensions>('yDimension');
  }
  public static get DH_DIMENSION() {
    return nameof<SettingDimensions>('dhDimension');
  }
  public static get Y1_DIMENSION() {
    return nameof<SettingDimensions>('y1Dimension');
  }
  public static get X1_DIMENSION() {
    return nameof<SettingDimensions>('x1Dimension');
  }

  public static get BUILDUP_LENGTH() {
    return nameof<BuildUpDimensions>('buildUpLength');
  }
  public static get BUILDUP_START() {
    return nameof<BuildUpDimensions>('buildUpStart');
  }
  public static get BUILDUP_WIDTH() {
    return nameof<BuildUpDimensions>('buildUpWidth');
  }

  public static get L_DIMENSION() {
    return nameof<TruckDimensions>('lDimension');
  }
  public static get WHEELBASE1() {
    return nameof<TruckDimensions>('wheelbase1');
  }
  public static get WHEELBASE2() {
    return nameof<TruckDimensions>('wheelbase2');
  }
  public static get CHASSIS_LOADED() {
    return nameof<TruckDimensions>('chassisLoaded');
  }
  public static get CHASSIS_UNLOADED() {
    return nameof<TruckDimensions>('chassisUnloaded');
  }
  public static get FENDER_SERIES() {
    return nameof<TruckDimensions>('fenderSeries');
  }
  public static get FRAME_WIDTH() {
    return nameof<TruckDimensions>('frameWidth');
  }
  public static get SUSPENSION_INSTALL_SPACE() {
    return nameof<TruckDimensions>('suspensionInstallSpace');
  }
  public static get WHEEL_WIDTH() {
    return nameof<TruckDimensions>('wheelWidth');
  }
  public static get H_UNLOADED_MIN() {
    return 750;
  }

  public static get H_UNLOADED_MAX() {
    return 1793;
  }

  public static get SETTINGS_DIMENSIONS() {
    return [
      this.C1_DIMENSION,
      this.C2_DIMENSION,
      this.C3_DIMENSION,
      this.D_DIMENSION,
      this.DK_DIMENSION,
      this.M_DIMENSION,
      this.R_DIMENSION,
      this.U_DIMENSION,
      this.V_DIMENSION,
      this.X_DIMENSION,
      this.Y_DIMENSION,
      this.DH_DIMENSION,
      this.X1_DIMENSION,
      this.Y1_DIMENSION,
    ];
  }

  public static get BUILDUP_DIMENSIONS() {
    return [
      this.C1_DIMENSION,
      this.C2_DIMENSION,
      this.C3_DIMENSION,
      this.BUILDUP_LENGTH,
      this.M_DIMENSION,
      this.BUILDUP_START,
      this.BUILDUP_WIDTH,
    ];
  }

  public static get TRUCK_DIMENSIONS() {
    return [
      this.WHEELBASE1,
      this.WHEELBASE2,
      this.L_DIMENSION,
      this.CHASSIS_UNLOADED,
      this.CHASSIS_LOADED,
      this.SUSPENSION_INSTALL_SPACE,
      this.FENDER_SERIES,
      this.R_DIMENSION,
      this.FRAME_WIDTH,
      this.WHEEL_WIDTH,
    ];
  }

  public static get GREATER_ZERO_DIMENSIONS() {
    return [
      this.H_DIMENSION_LOADED,
      this.DH_DIMENSION,
      this.M_DIMENSION,
      this.R_DIMENSION,
      this.C1_DIMENSION,
      this.C3_DIMENSION,
      this.Y_DIMENSION,
      this.Y1_DIMENSION,
      this.BUILDUP_LENGTH,
    ];
  }

  public static get ZERO_OR_GREATER_DIMENSIONS() {
    return [
      this.DK_DIMENSION,
      this.D_DIMENSION,
      this.N_DIMENSION,
      this.U_DIMENSION,
      this.V_DIMENSION,
      this.C2_DIMENSION,
    ];
  }

  public static get ArmLengthThresholds() {
    return [
      {
        maxHeight: 749,
        value: -1,
      },
      {
        maxHeight: 900,
        value: 600,
      },
      {
        maxHeight: 1050,
        value: 700,
      },
      {
        maxHeight: 1150,
        value: 800,
      },
      {
        maxHeight: 1300,
        value: 900,
      },
      {
        maxHeight: 1700,
        value: 1000,
      },
      {
        maxHeight: 1793,
        value: 1100,
      },
    ];
  }

  public static get DefaultDhDimension() {
    return 180;
  }
}
