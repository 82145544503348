import { createAction, props } from '@ngrx/store';
import { Project } from '../../../shared/generated';

export namespace CurrentProjectActions {
  export const SetName = createAction(
    '[FeasibilityStudy] SET_PROJECT_NAME',
    props<{ payload: string | null }>(),
  );

  export const SetCommission = createAction(
    '[FeasibilityStudy] SET_PROJECT_COMMISSION',
    props<{ payload: string | null }>(),
  );

  export const SaveProject = createAction(
    '[FeasibilityStudy] SAVE_PROJECT',
    props<{ saveAs: boolean }>(),
  );

  export const SaveProjectSuccess = createAction(
    '[FeasibilityStudy] SAVE_PROJECT_SUCCESS',
    props<{ id: number; isLocked: boolean }>(),
  );

  export const SaveProjectError = createAction(
    '[FeasibilityStudy] SAVE_PROJECT_ERROR',
  );

  export const SendRequest = createAction('[FeasibilityStudy] SEND_REQUEST');
  export const SendRequestSuccess = createAction(
    '[FeasibilityStudy] SEND_REQUEST_SUCCESS',
    props<{ payload: Project }>(),
  );
  export const SendRequestError = createAction(
    '[FeasibilityStudy] SEND_REQUEST_ERROR',
  );

  export const LoadProject = createAction(
    '[FeasibilityStudy] LOAD_PROJECT',
    props<{ id: number }>(),
  );

  export const LoadProjectSuccess = createAction(
    '[FeasibilityStudy] LOAD_PROJECT_SUCCESS',
    props<{ payload: Project }>(),
  );

  export const LoadProjectError = createAction(
    '[FeasibilityStudy] LOAD_PROJECT_ERROR',
  );

  export const Reset = createAction('[FeasibilityStudy] RESET_PROJECT');
}
