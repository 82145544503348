import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { APPS } from '@config';
import { AuthGuard } from '@features/auth';
import { RedirectGuard } from './shared/guard/redirect-guard';

export const appRoles = [
  APPS.FEASIBILITY_STUDY.ROLES.USER,
  APPS.FEASIBILITY_STUDY.ROLES.SUPER_USER,
  APPS.FEASIBILITY_STUDY.ROLES.ADMIN,
];

export const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [() => inject(RedirectGuard).canActivate()],
      },
      {
        path: 'project',
        loadChildren: () =>
          import('./projects/project-process/project-process.module').then(
            (m) => m.ProjectProcessModule,
          ),
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./projects/projects.module').then((m) => m.ProjectsModule),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            roles: appRoles,
          },
          breadcrumb: 'feasibility_study.sidebar.projects',
        },
      },
      {
        path: 'callback',
        children: [],
      },
      {
        path: 'special',
        loadChildren: () =>
          import('@design-system/feature/special-pages').then(
            (m) => m.DsSpecialPagesModule,
          ),
      },
      {
        path: '**',
        redirectTo: 'special',
      },
    ],
  },
];
