import { createSelector } from '@ngrx/store';
import { FeasibilityStudyState } from '..';
import { TailLiftFilterRequest } from '../../shared/generated';
import {
  BuildUpDimensions,
  Options,
  SettingDimensions,
  TruckDimensions,
} from '../../shared/models/models';
import { TruckSelectors } from '../truck';
import { SettingsState } from './settings.reducer';

export namespace SettingsSelectors {
  export const settingsState = (state: FeasibilityStudyState) => state.settings;

  export const getFocusedDimension = createSelector(
    settingsState,
    (state: SettingsState) => state.focusedDimension,
  );

  export const getSettingDimensions = createSelector(
    settingsState,
    (state: SettingsState) =>
      <SettingDimensions>{
        c1Dimension: state.c1Dimension,
        c2Dimension: state.c2Dimension,
        c3Dimension: state.c3Dimension,
        cDimension: state.cDimension,
        dDimension: state.dDimension,
        dhDimension: state.dhDimension,
        dkDimension: state.dkDimension,
        hDimensionLoaded: state.hDimensionLoaded,
        hDimensionUnLoaded: state.hDimensionUnLoaded,
        mDimension: state.mDimension,
        nDimension: state.nDimension,
        rDimension: state.rDimension,
        uDimension: state.uDimension,
        vDimension: state.vDimension,
        x1Dimension: state.x1Dimension,
        xDimension: state.xDimension,
        y1Dimension: state.y1Dimension,
        yDimension: state.yDimension,
      },
  );

  export const getTailliftFilterData = createSelector(
    settingsState,
    TruckSelectors.getTruckDetails,
    (state, truckDetails) =>
      <TailLiftFilterRequest>{
        dimension_c3: state.c3Dimension,
        dimension_dk: state.dkDimension,
        dimension_d: state.dDimension,
        dimension_h_loaded: state.hDimensionLoaded,
        dimension_h_unloaded: state.hDimensionUnLoaded,
        dimension_y: state.hasJawCoupling ? state.yDimension : 0, // TODO: provide hasJawCoupling instead of y
        dimension_e: state.eDimension,
        gross_vehicle_weight: truckDetails?.gross_vehicle_weight,
      },
  );

  export const getBuildUpDimensions = createSelector(
    settingsState,
    (state) =>
      <BuildUpDimensions>{
        buildUpLength: state.buildUpLength,
        buildUpStart: state.buildUpStart,
        buildUpWidth: state.buildUpWidth,
        c1Dimension: state.c1Dimension,
        c2Dimension: state.c2Dimension,
        c3Dimension: state.c3Dimension,
        mDimension: state.mDimension,
      },
  );

  export const getLDimension = createSelector(
    settingsState,
    (state) => state.lDimension,
  );

  export const getTruckDimensions = createSelector(
    settingsState,
    (state) =>
      <TruckDimensions>{
        chassisLoaded: state.chassisLoaded,
        chassisUnloaded: state.chassisUnloaded,
        fenderSeries: state.fenderSeries,
        lDimension: state.lDimension,
        rDimension: state.rDimension,
        suspensionInstallSpace: state.suspensionInstallSpace,
        wheelbase1: state.wheelbase1,
        wheelbase2: state.wheelbase2,
        frameWidth: state.eDimension,
        wheelWidth: state.wheelWidth,
      },
  );

  export const getSuggestedArmLength = createSelector(
    settingsState,
    (state) => state.suggestedArmLength,
  );

  export const getOptions = createSelector(
    settingsState,
    (state) =>
      <Options>{
        bracket: state.bracket,
        support: state.support,
        hasJawCoupling: state.hasJawCoupling,
        coupling: state.coupling,
        isGClass: state.isGClass,
      },
  );

  export const getDimensionsLoading = createSelector(
    settingsState,
    (state) => state.dimensionsLoading,
  );

  export const getBracket = createSelector(
    settingsState,
    (state) => state.bracket,
  );
}
