import { createSelector } from '@ngrx/store';
import { FeasibilityStudyState } from '../..';
import {
  AutoStudyRequest,
  Bracket,
  ProjectRequest,
  ProjectStatus,
  TruckCouplingType,
} from '../../../shared/generated';
import { Options, SettingDimensions } from '../../../shared/models/models';
import { Constants } from '../../../shared/utils/constants';
import { SettingsSelectors } from '../../settings';
import { TailLiftSelectors } from '../../tail-lift';
import { TruckSelectors } from '../../truck';

export namespace CurrentProjectSelectors {
  export const currentProjectState = (state: FeasibilityStudyState) =>
    state.currentProject;

  export const getName = createSelector(
    currentProjectState,
    (state) => state.name,
  );
  export const getCommission = createSelector(
    currentProjectState,
    (state) => state.commission,
  );
  export const getIsLocked = createSelector(
    currentProjectState,
    (state) => state.isLocked,
  );

  export const canSendCalculationRequest = createSelector(
    TruckSelectors.truckState,
    TailLiftSelectors.getSelectedTailLift,
    (truckState, selectedTailLift) =>
      truckState.selectedTruckId > 0 && !!selectedTailLift,
  );

  export const canEnableTailliftSelectionStep = createSelector(
    SettingsSelectors.getSettingDimensions,
    SettingsSelectors.getOptions,
    (dimensions, options) =>
      (dimensions.c1Dimension || 0) > 0 &&
      dimensions.c2Dimension !== null &&
      dimensions.c2Dimension >= 0 &&
      (dimensions.c3Dimension || 0) > 0 &&
      dimensions.dkDimension !== null &&
      dimensions.dkDimension >= 0 &&
      dimensions.dDimension !== null &&
      dimensions.dDimension > 0 &&
      (dimensions.dhDimension || 0) > 0 &&
      (dimensions.hDimensionLoaded || 0) > 0 &&
      (dimensions.hDimensionUnLoaded || 0) >= Constants.H_UNLOADED_MIN &&
      (dimensions.hDimensionUnLoaded || 0) <= Constants.H_UNLOADED_MAX &&
      (dimensions.mDimension || 0) > 0 &&
      dimensions.nDimension !== null &&
      dimensions.nDimension >= 0 &&
      (dimensions.rDimension || 0) > 0 &&
      dimensions.uDimension !== null &&
      dimensions.uDimension >= 0 &&
      dimensions.vDimension !== null &&
      dimensions.vDimension >= 0 &&
      jawCouplingDimensionsValid(options, dimensions) &&
      deepCouplingDimensionsValid(options, dimensions),
  );

  export const isAdaptionsStepCompleted = createSelector(
    SettingsSelectors.settingsState,
    (settingsState) =>
      (settingsState.wheelbase1 || 0) > 0 &&
      (settingsState.wheelbase2 || 0) >= 0 &&
      (settingsState.lDimension || 0) > 0 &&
      (settingsState.chassisUnloaded || 0) > 0 &&
      (settingsState.chassisLoaded || 0) > 0 &&
      (settingsState.suspensionInstallSpace || 0) >= 0 &&
      (settingsState.fenderSeries || 0) >= 0 &&
      (settingsState.rDimension || 0) > 0 &&
      (settingsState.eDimension || 0) > 0 &&
      (settingsState.wheelWidth || 0) > 0,
  );

  export const isTruckStepCompleted = createSelector(
    TruckSelectors.truckState,
    (truckstate) => truckstate.selectedTruckId > 0,
  );

  export const isBuiltUpStepCompleted = createSelector(
    SettingsSelectors.settingsState,
    (settingsState) =>
      (settingsState.mDimension || 0) >= (settingsState.lDimension || 0) &&
      (settingsState.buildUpLength || 0) > 0,
  );

  export const canEnableOptionsStep = createSelector(
    SettingsSelectors.settingsState,
    (settingsState) =>
      !!settingsState.buildUpWidth &&
      !!settingsState.buildUpStart &&
      !!settingsState.buildUpLength,
  );
  export const canEnableResultStep = createSelector(
    currentProjectState,
    (state) =>
      !state.isRequestPending &&
      state.autoStudyResult?.status === ProjectStatus.Completed,
  );
  export const getIsProjectSaving = createSelector(
    currentProjectState,
    (state) => state.isProjectSaving,
  );
  export const getIsProjectLoading = createSelector(
    currentProjectState,
    (state) => state.isProjectLoading,
  );
  export const getIsRequestPending = createSelector(
    currentProjectState,
    (state) => state.isRequestPending,
  );
  export const getProjectData = createSelector(
    currentProjectState,
    TruckSelectors.getSelectedTruck,
    SettingsSelectors.settingsState,
    TailLiftSelectors.getSelectedTailLift,
    (projectState, selectedTruck, settingsState, selectedTailLift) =>
      <ProjectRequest>{
        id: projectState.id,
        name: projectState.name,
        commission: projectState.commission,
        truck_id: selectedTruck?.id,
        tail_lift_id: selectedTailLift?.id,
        dimension_c1: settingsState.c1Dimension,
        dimension_c2: settingsState.c2Dimension,
        dimension_c3: settingsState.c3Dimension,
        dimension_dk: settingsState.dkDimension,
        dimension_c: settingsState.cDimension,
        dimension_d: settingsState.dDimension,
        dimension_m: settingsState.mDimension,
        dimension_n: settingsState.nDimension,
        dimension_r: settingsState.rDimension,
        dimension_u: settingsState.uDimension,
        dimension_v: settingsState.vDimension,
        dimension_dh: settingsState.dhDimension,
        dimension_hl: settingsState.hDimensionLoaded,
        dimension_hu: settingsState.hDimensionUnLoaded,
        dimension_x: settingsState.xDimension,
        dimension_y: settingsState.yDimension,
        dimension_x1: settingsState.x1Dimension,
        dimension_y1: settingsState.y1Dimension,
        bracket_type: settingsState.bracket,
        coupling_type: settingsState.coupling,
        g_class: settingsState.isGClass,
        jaw_coupling: settingsState.hasJawCoupling,
        support_type: settingsState.support,
        buildup_length: settingsState.buildUpLength,
        buildup_width: settingsState.buildUpWidth,
        truck_adaptions: {
          chassis_loaded: settingsState.chassisLoaded,
          chassis_unloaded: settingsState.chassisUnloaded,
          e: settingsState.eDimension,
          fender_series: settingsState.fenderSeries,
          r: settingsState.rDimension,
          rear_overhang: settingsState.lDimension,
          suspension_install_space: settingsState.suspensionInstallSpace,
          track_width: settingsState.wheelWidth,
          wheelbase: settingsState.wheelbase1,
          wheelbase2: settingsState.wheelbase2,
        },
      },
  );

  export const getAutostudyData = createSelector(
    currentProjectState,
    TruckSelectors.getSelectedTruck,
    SettingsSelectors.settingsState,
    TailLiftSelectors.getSelectedTailLift,
    (projectState, selectedTruck, settings, selectedTailLift) =>
      <AutoStudyRequest>{
        id: projectState.id,
        name: projectState.name,
        commission: projectState.commission,
        truck_id: selectedTruck?.id,
        tail_lift_id: selectedTailLift?.id,
        dimension_c1: settings.c1Dimension,
        dimension_c2: settings.c2Dimension,
        dimension_c3: settings.c3Dimension,
        dimension_dk: settings.dkDimension,
        dimension_c: settings.cDimension,
        dimension_d: settings.dDimension,
        dimension_m: settings.mDimension,
        dimension_n: settings.nDimension,
        dimension_r: settings.rDimension,
        dimension_u: settings.uDimension,
        dimension_v: settings.vDimension,
        dimension_dh: settings.dhDimension,
        dimension_hl: settings.hDimensionLoaded,
        dimension_hu: settings.hDimensionUnLoaded,
        dimension_x: settings.xDimension,
        dimension_y: settings.yDimension,
        dimension_x1: settings.x1Dimension,
        dimension_y1: settings.y1Dimension,
        bracket_type: settings.bracket || Bracket.Welded,
        coupling_type: settings.coupling,
        g_class: settings.isGClass,
        jaw_coupling: settings.hasJawCoupling,
        support_type: settings.support,
        buildup_length: settings.buildUpLength,
        buildup_width: settings.buildUpWidth || 0,
        truck_adaptions: {
          chassis_loaded: settings.chassisLoaded,
          chassis_unloaded: settings.chassisUnloaded,
          e: settings.eDimension,
          fender_series: settings.fenderSeries,
          r: settings.rDimension,
          rear_overhang: settings.lDimension,
          suspension_install_space: settings.suspensionInstallSpace,
          track_width: settings.wheelWidth,
          wheelbase: settings.wheelbase1,
          wheelbase2: settings.wheelbase2,
        },
      },
  );

  export const getPdfUrl = createSelector(
    currentProjectState,
    (state) => state.pdfUrl,
  );

  export const getDxfUrl = createSelector(
    currentProjectState,
    (state) => state.dxfUrl,
  );

  export const getImageUrl = createSelector(
    currentProjectState,
    (state) => state.imageUrl,
  );

  export const getErrors = createSelector(
    currentProjectState,
    (state) => state.errors,
  );
}

function deepCouplingDimensionsValid(
  options: Options,
  dimensions: SettingDimensions,
): boolean {
  return (
    options.coupling !== TruckCouplingType.DeepCoupling ||
    (dimensions.x1Dimension !== null && (dimensions.y1Dimension || 0) > 0)
  );
}

function jawCouplingDimensionsValid(
  options: Options,
  dimensions: SettingDimensions,
) {
  return (
    !options.hasJawCoupling ||
    (dimensions.xDimension !== null && (dimensions.yDimension || 0) > 0)
  );
}
