import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Truck, TruckDetails } from '../../shared/generated';
import { TruckActions } from './truck.actions';

export interface TruckState extends EntityState<Truck> {
  truckVariantsResultLoading: boolean;
  truckVariantsResultLoadingError: boolean;
  truckDetailsLoading: boolean;
  truckDetails: TruckDetails | null;
  selectedTruckId: number;
}

export const adapter: EntityAdapter<Truck> = createEntityAdapter<Truck>();

const initialState: TruckState = adapter.getInitialState({
  truckVariantsResultLoading: false,
  truckVariantsResultLoadingError: false,
  truckDetailsLoading: false,
  truckDetails: null,
  selectedTruckId: 0,
});

export const reducer = createReducer(
  initialState,

  on(TruckActions.LoadTruckVariants, (state) => ({
    ...state,
    truckVariantsResultLoading: true,
    truckVariantsResultLoadingError: false,
  })),

  on(TruckActions.LoadTruckVariantsSuccess, (state, { payload }) =>
    adapter.setAll(payload, {
      ...state,
      truckVariantsResultLoading: false,
    }),
  ),

  on(TruckActions.LoadTruckVariantsError, (state) => ({
    ...state,
    truckVariantsResultLoading: false,
    truckVariantsResultLoadingError: true,
  })),

  on(TruckActions.SetSelectedTruck, (state, { payload }) =>
    payload
      ? adapter.upsertOne(payload, {
          ...state,
          selectedTruckId: payload.id,
        })
      : {
          ...state,
          selectedTruckId: 0,
        },
  ),

  on(TruckActions.SelectTruckId, (state, { id }) => ({
    ...state,
    selectedTruckId: id,
  })),

  on(TruckActions.LoadTruckDetails, (state) => ({
    ...state,
    truckDetailsLoading: true,
  })),
  on(TruckActions.LoadTruckDetailsSuccess, (state, { payload }) => ({
    ...state,
    truckDetails: payload,
    truckDetailsLoading: false,
  })),
  on(TruckActions.LoadTruckDetailsError, (state) => ({
    ...state,
    truckDetailsLoading: false,
  })),

  on(TruckActions.Reset, () => ({
    ...initialState,
  })),
);

export function truckReducer(state: TruckState | undefined, action: Action) {
  return reducer(state, action);
}
