import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GaService } from '@shared-lib/google-analytics';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { FeasibilityStudyState } from './store';
import { UserActions } from './store/user/user.actions';

@Component({
  selector: 'fs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private store: Store<FeasibilityStudyState>,
    private _gaService: GaService,
    private _angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {
    this._gaService.create();
    this._angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit(): void {
    this.store.dispatch(UserActions.checkUser());
  }
}
