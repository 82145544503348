import { createAction, props } from '@ngrx/store';
import {
  Bracket,
  TruckCouplingType,
  TruckDetails,
  TruckSupportType,
} from '../../shared/generated';

export namespace SettingsActions {
  export const SetFocusedDimension = createAction(
    '[FeasibilityStudy] SET_FOCUSED_DIMENSION',
    props<{
      payload: { dimension: string };
    }>(),
  );

  // dimensions

  export const SetMDimension = createAction(
    '[FeasibilityStudy] SET_M_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetDkDimension = createAction(
    '[FeasibilityStudy] SET_DK_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetDDimension = createAction(
    '[FeasibilityStudy] SET_D_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetHLoadedDimension = createAction(
    '[FeasibilityStudy] SET_H_LOADED_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetHUnloadedDimension = createAction(
    '[FeasibilityStudy] SET_H_UNLOADED_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetRDimension = createAction(
    '[FeasibilityStudy] SET_R_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetC1Dimension = createAction(
    '[FeasibilityStudy] SET_C1_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetC2Dimension = createAction(
    '[FeasibilityStudy] SET_C2_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetC3Dimension = createAction(
    '[FeasibilityStudy] SET_C3_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetNDimension = createAction(
    '[FeasibilityStudy] SET_N_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetUDimension = createAction(
    '[FeasibilityStudy] SET_U_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetVDimension = createAction(
    '[FeasibilityStudy] SET_V_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetYDimension = createAction(
    '[FeasibilityStudy] SET_Y_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetXDimension = createAction(
    '[FeasibilityStudy] SET_X_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetDhDimension = createAction(
    '[FeasibilityStudy] SET_DH_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetY1Dimension = createAction(
    '[FeasibilityStudy] SET_Y1_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetX1Dimension = createAction(
    '[FeasibilityStudy] SET_X1_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetBuildUpLength = createAction(
    '[FeasibilityStudy] SET_BUILDUP_LENGTH',
    props<{ payload: number }>(),
  );

  export const SetBuildUpWidth = createAction(
    '[FeasibilityStudy] SET_BUILDUP_WIDTH',
    props<{ payload: number }>(),
  );

  export const SetbuildUpStart = createAction(
    '[FeasibilityStudy] SET_BUILDUP_START',
    props<{ payload: number }>(),
  );

  export const SetWheelbase1 = createAction(
    '[FeasibilityStudy] SET_WHEELBASE1',
    props<{ payload: number }>(),
  );

  export const SetWheelbase2 = createAction(
    '[FeasibilityStudy] SET_WHEELBASE2',
    props<{ payload: number }>(),
  );

  export const SetLDimension = createAction(
    '[FeasibilityStudy] SET_L_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetFenderSeries = createAction(
    '[FeasibilityStudy] SET_FENDER_SERIES',
    props<{ payload: number }>(),
  );

  export const SetSuspensionInstallSpace = createAction(
    '[FeasibilityStudy] SET_SUSPENSION_INSTALLSPACE',
    props<{ payload: number }>(),
  );

  export const SetChassisLoaded = createAction(
    '[FeasibilityStudy] SET_CHASSIS_LOADED',
    props<{ payload: number }>(),
  );

  export const SetChassisUnlaoded = createAction(
    '[FeasibilityStudy] SET_CHASSIS_UNLOADED',
    props<{ payload: number }>(),
  );

  export const SetEDimension = createAction(
    '[FeasibilityStudy] SET_E_DIMENSION',
    props<{ payload: number }>(),
  );

  export const SetWheelWidth = createAction(
    '[FeasibilityStudy] SET_WHEEL_WIDTH',
    props<{ payload: number }>(),
  );

  export const Reset = createAction('[FeasibilityStudy] RESET_SETTINGS');

  export const SetBracket = createAction(
    '[FeasibilityStudy] SET_BRACKET',
    props<{ payload: Bracket }>(),
  );

  export const SetSupport = createAction(
    '[FeasibilityStudy] SET_SUPPORT',
    props<{ payload: TruckSupportType }>(),
  );

  export const SetHasJawCoupling = createAction(
    '[FeasibilityStudy] SET_HAS_JAW_COUPLING',
    props<{ payload: boolean }>(),
  );

  export const SetCoupling = createAction(
    '[FeasibilityStudy] SET_HAS_LOW_COUPLING',
    props<{ payload: TruckCouplingType }>(),
  );

  export const SetIsGClass = createAction(
    '[FeasibilityStudy] SET_IS_G_CLASS',
    props<{ payload: boolean }>(),
  );

  export const SetTruckAdaptionDimensions = createAction(
    '[FeasibilityStudy] SET_DIMENSIONS_FROM_TRUCK',
    props<{ payload: TruckDetails }>(),
  );

  export const SetMaxJawCouplingWidth = createAction(
    '[FeasibilityStudy] SET_MAX_JAW_COUPLING_WIDTH',
    props<{ payload: number }>(),
  );
}
