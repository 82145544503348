import { RootEffects, rootReducer, RootState } from '@features/root-store';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';
import { CurrentProjectEffects } from './projects/current/current-project.effects';
import {
  currentProjectReducer,
  CurrentProjectState,
} from './projects/current/current-project.reducer';
import { ProjectsEffects } from './projects/projects.effects';
import { projectsReducer, ProjectsState } from './projects/projects.reducer';
import { settingsReducer, SettingsState } from './settings';
import { SettingsEffects } from './settings/settings.effects';
import { SnackbarEffects } from './snackbar';
import { TailLiftEffects, TailLiftState, tailLliftReducer } from './tail-lift';
import { TruckEffects, truckReducer, TruckState } from './truck';
import { UserEffects } from './user/user.effects';
import { userReducer, UserState } from './user/user.reducer';

export interface FeasibilityStudyState {
  root: RootState;
  truck: TruckState;
  tailLift: TailLiftState;
  settings: SettingsState;
  currentProject: CurrentProjectState;
  projects: ProjectsState;
  user: UserState;
}

export const feasibilityStudyReducers: ActionReducerMap<
  FeasibilityStudyState,
  any
> = {
  root: rootReducer,
  truck: truckReducer,
  tailLift: tailLliftReducer,
  settings: settingsReducer,
  currentProject: currentProjectReducer,
  projects: projectsReducer,
  user: userReducer,
};

export const feasibilityStudyEffects = [
  EffectsModule.forFeature([
    RootEffects,
    TruckEffects,
    TailLiftEffects,
    SettingsEffects,
    CurrentProjectEffects,
    ProjectsEffects,
    SnackbarEffects,
    UserEffects,
  ]),
];
