import { Injectable, inject } from '@angular/core';
import { UserService } from '@features/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { TailLiftsService } from '../../shared/generated';
import { SettingsSelectors } from '../settings';
import { TailLiftActions } from './tail-lift.actions';

@Injectable()
export class TailLiftEffects {
  private store = inject(Store);
  private userService = inject(UserService);
  private tailLiftsService = inject(TailLiftsService);

  loadTailLifts$ = createEffect(() =>
    inject(Actions).pipe(
      ofType(TailLiftActions.LoadTailLifts),
      withLatestFrom(
        this.store.select(SettingsSelectors.getTailliftFilterData),
        this.userService.isAuthorized$,
      ),
      map(([, dimensions, isAuthorized]) => ({ dimensions, isAuthorized })),
      filter((x) => !!x.dimensions),
      switchMap((x) =>
        (x.isAuthorized
          ? this.tailLiftsService.getFilteredTailLifts(x.dimensions)
          : this.tailLiftsService.publicGetFilteredTailLifts(x.dimensions)
        ).pipe(
          map((tailLiftsResponse) =>
            TailLiftActions.LoadTailLiftsSuccess({
              payload: { tailLlifts: tailLiftsResponse },
            }),
          ),
          catchError(() => of(TailLiftActions.LoadTailLiftsError())),
        ),
      ),
    ),
  );
}
