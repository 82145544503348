import { createAction, props } from '@ngrx/store';
import { Truck, TruckDetails } from '../../shared/generated';

export namespace TruckActions {
  export const LoadTruckVariants = createAction(
    '[FeasibilityStudy] LOAD_TRUCK_VARIANTS',
  );

  export const LoadTruckVariantsSuccess = createAction(
    '[FeasibilityStudy] LOAD_TRUCK_VARIANTS_SUCCESS',
    props<{
      payload: Truck[];
    }>(),
  );

  export const LoadTruckVariantsError = createAction(
    '[FeasibilityStudy] LOAD_TRUCK_VARIANTS_ERROR',
  );

  export const LoadTruckDetails = createAction(
    '[FeasibilityStudy] LOAD_TRUCK_DETAILS',
    props<{ id: number }>(),
  );

  export const LoadTruckDetailsSuccess = createAction(
    '[FeasibilityStudy] LOAD_TRUCK_DETAILS_SUCCESS',
    props<{ payload: TruckDetails }>(),
  );

  export const LoadTruckDetailsError = createAction(
    '[FeasibilityStudy] LOAD_TRUCK_DETAILS_ERROR',
  );

  export const SetSelectedTruck = createAction(
    '[FeasibilityStudy] SET_TRUCK',
    props<{ payload: Truck | undefined }>(),
  );

  export const SelectTruckId = createAction(
    '[FeasibilityStudy] SELECT_TRUCK',
    props<{
      id: number;
    }>(),
  );

  export const Reset = createAction('[FeasibilityStudy] RESET');
}
