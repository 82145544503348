/**
 * Palfinger.Microservice.TailLiftStudy
 * API designed to provide the Tail Lift Auto Study application
 *
 * The version of the OpenAPI document: v1
 * Contact: S.Vates@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum BracketType {
    None = 'None',
    Any = 'Any',
    Bolted = 'Bolted',
    Welded = 'Welded'
};

