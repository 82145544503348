import { createAction, props } from '@ngrx/store';
import { Projects } from '../../shared/generated/model/projects';

export namespace ProjectsActions {
  export const LoadProjects = createAction('[FeasibilityStudy] LOAD_PROJECTS');

  export const LoadProjectsSuccess = createAction(
    '[FeasibilityStudy] LOAD_PROJECTS_SUCCESS',
    props<{ payload: Projects }>(),
  );

  export const LoadProjectsError = createAction(
    '[FeasibilityStudy] LOAD_PROJECTS_ERROR',
  );

  export const DeleteProject = createAction(
    '[FeasibilityStudy] DELETE_PROJECT',
    props<{ payload: number }>(),
  );

  export const DeleteProjectSuccess = createAction(
    '[FeasibilityStudy] DELETE_PROJECT_SUCCESS',
    props<{ payload: number }>(),
  );
  export const DeleteProjectError = createAction(
    '[FeasibilityStudy] DELETE_PROJECT_ERROR',
  );

  export const LockProject = createAction(
    '[FeasibilityStudy] LOCK_PROJECT',
    props<{ payload: number }>(),
  );

  export const UnlockProject = createAction(
    '[FeasibilityStudy] UNLOCK_PROJECT',
    props<{ payload: number }>(),
  );

  export const SetProjectLock = createAction(
    '[FeasibilityStudy] SET_PROJECT_LOCK',
    props<{ payload: { id: number; isLocked: boolean } }>(),
  );
}
