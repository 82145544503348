import { Injectable, inject } from '@angular/core';
import { APPS } from '@config';
import { UserService } from '@features/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, take, withLatestFrom } from 'rxjs';
import { UserActions } from './user.actions';

@Injectable()
export class UserEffects {
  private userService: UserService = inject(UserService);

  checkUserAuthenticated$ = createEffect(() =>
    inject(Actions).pipe(
      ofType(UserActions.checkUser),
      switchMap(() =>
        this.userService.isAuthorized$.pipe(
          withLatestFrom(this.userService.currentUser),
          take(1),
          switchMap(([isAuthorized, currentUser]) => [
            UserActions.setUserAuthenticated({ payload: isAuthorized }),
            UserActions.setUserAdmin({
              payload:
                currentUser?.roles.includes(
                  APPS.FEASIBILITY_STUDY.ROLES.ADMIN,
                ) || false,
            }),
          ]),
        ),
      ),
    ),
  );
}
