import { Constants } from '../../shared/utils/constants';
import { SettingsState } from './settings.reducer';

export function getArmLength(hUnloaded: number) {
  for (let i = 0; i < Constants.ArmLengthThresholds.length; i++) {
    const threshold = Constants.ArmLengthThresholds[i];
    if (hUnloaded <= threshold.maxHeight) return threshold.value;
  }

  return -1;
}

export function calculateStateByMDimension(
  state: SettingsState,
): SettingsState {
  state = {
    ...state,
    nDimension: calculateN(state),
  };
  state = {
    ...state,
    dDimension: calculateD(state),
  };
  state = {
    ...state,
    dkDimension: calculateDk(state),
  };
  state = {
    ...state,
    buildUpLength: calculateBuildUpLength(state),
  };
  state = {
    ...state,
    xDimension: calculateX(state),
  };

  return state;
}

export function calculateStateByCDimensions(
  state: SettingsState,
): SettingsState {
  const hUnloaded = calculateHUnloaded(state);
  const c =
    (state.c1Dimension || 0) +
    (state.c2Dimension || 0) +
    (state.c3Dimension || 0) +
    (state.rDimension || 0);
  return {
    ...state,
    hDimensionUnLoaded: hUnloaded,
    hDimensionLoaded: calculateHLoaded(state),
    suggestedArmLength: getArmLength(hUnloaded || 0),
    cDimension: c,
    yDimension:
      !state.yDimension || state.yDimension === state.cDimension
        ? c
        : state.yDimension,
  };
}

export function calculateM(s: SettingsState): number | null {
  if (s.buildUpLength && s.buildUpStart && s.wheelbase1)
    return (
      s.buildUpLength + s.buildUpStart - s.wheelbase1 - (s.wheelbase2 || 0)
    );

  return s.mDimension;
}

export function calculateN(s: SettingsState): number | null {
  if (s.mDimension && s.lDimension) return s.mDimension - s.lDimension;

  if (s.buildUpLength && s.buildUpStart && s.wheelbase1 && s.lDimension)
    return (
      s.buildUpLength +
      s.buildUpStart -
      (s.wheelbase1 + (s.wheelbase2 || 0) + s.lDimension)
    );

  return s.nDimension;
}

export function calculateD(s: SettingsState): number | null {
  if (s.mDimension && s.suspensionInstallSpace)
    return s.mDimension - s.suspensionInstallSpace;

  if (
    s.buildUpLength &&
    s.buildUpStart &&
    s.wheelbase1 &&
    s.suspensionInstallSpace
  )
    return (
      s.buildUpLength +
      s.buildUpStart -
      (s.wheelbase1 + (s.wheelbase2 || 0) + s.suspensionInstallSpace)
    );

  return s.dDimension;
}

export function calculateDk(s: SettingsState): number | null {
  if (s.mDimension && s.fenderSeries) return s.mDimension - s.fenderSeries;

  if (s.buildUpLength && s.buildUpStart && s.wheelbase1 && s.fenderSeries)
    return (
      s.buildUpLength +
      s.buildUpStart -
      (s.wheelbase1 + (s.wheelbase2 || 0) + s.fenderSeries)
    );

  return s.dkDimension;
}

export function calculateBuildUpLength(s: SettingsState): number | null {
  if (s.mDimension && s.wheelbase1 && s.buildUpStart !== null)
    return s.wheelbase1 + (s.wheelbase2 || 0) + s.mDimension - s.buildUpStart;

  if (s.nDimension && s.lDimension && s.wheelbase1 && s.buildUpStart !== null)
    return (
      s.wheelbase1 +
      (s.wheelbase2 || 0) +
      s.nDimension +
      s.lDimension -
      s.buildUpStart
    );

  return s.buildUpLength;
}

export function calculateHUnloaded(s: SettingsState): number | null {
  if (s.chassisUnloaded)
    return (
      s.chassisUnloaded +
      (s.c1Dimension || 0) +
      (s.c2Dimension || 0) +
      (s.c3Dimension || 0)
    );

  return s.hDimensionUnLoaded;
}

export function calculateHLoaded(s: SettingsState): number | null {
  if (s.chassisLoaded)
    return (
      s.chassisLoaded +
      (s.c1Dimension || 0) +
      (s.c2Dimension || 0) +
      (s.c3Dimension || 0)
    );

  return s.hDimensionLoaded;
}

export function calculateX(state: SettingsState): number | null {
  if (state.maxJawCouplingWidth && state.nDimension !== null) {
    return Math.max(state.nDimension - state.maxJawCouplingWidth, 0);
  }
  return state.xDimension;
}
