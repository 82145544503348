import { createAction, props } from '@ngrx/store';
import { TailLifts } from '../../shared/generated';

export namespace TailLiftActions {
  export const LoadTailLifts = createAction(
    '[FeasibilityStudy] LOAD_TAIL_LIFTS',
  );

  export const LoadTailLiftsSuccess = createAction(
    '[FeasibilityStudy] LOAD_TAIL_LIFTS_SUCCESS',
    props<{
      payload: { tailLlifts: TailLifts };
    }>(),
  );

  export const LoadTailLiftsError = createAction(
    '[FeasibilityStudy] LOAD_TAIL_LIFTS_ERROR',
  );

  export const SelectTailLiftId = createAction(
    '[FeasibilityStudy] SELECT_TAILLIFT',
    props<{
      id: number;
    }>(),
  );

  export const Reset = createAction('[FeasibilityStudy] RESET_TAILLIFT');
}
