import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { SettingsActions } from './settings.actions';

@Injectable()
export class SettingsEffects {
  setTruckDimensions$ = createEffect(() =>
    inject(Actions).pipe(
      ofType(SettingsActions.SetTruckAdaptionDimensions),
      map((a) => a.payload),
      mergeMap((details) => [
        SettingsActions.SetWheelbase1({ payload: details.wheelbase }),
        SettingsActions.SetWheelbase2({ payload: details.wheelbase2 }),
        SettingsActions.SetEDimension({ payload: details.e }),
        SettingsActions.SetWheelWidth({ payload: details.track_width }),
        SettingsActions.SetLDimension({ payload: details.rear_overhang }),
        SettingsActions.SetRDimension({ payload: details.r }),
        SettingsActions.SetFenderSeries({ payload: details.fender_series }),
        SettingsActions.SetSuspensionInstallSpace({
          payload: details.suspension_install_space,
        }),
        SettingsActions.SetChassisLoaded({
          payload: details.chassis_loaded,
        }),
        SettingsActions.SetChassisUnlaoded({
          payload: details.chassis_unloaded,
        }),
        SettingsActions.SetMaxJawCouplingWidth({
          payload: details.max_jaw_coupling_width,
        }),
      ]),
    ),
  );
}
