import { createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter, EntityState } from '@ngrx/entity/src/models';
import { Action, createReducer, on } from '@ngrx/store';
import { ProjectPreview } from '../../shared/generated';
import { ProjectsActions } from './projects.actions';

export interface ProjectsState extends EntityState<ProjectPreview> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<ProjectPreview> =
  createEntityAdapter<ProjectPreview>();

export const initialState: ProjectsState = adapter.getInitialState({
  isLoading: false,
});

const reducer = createReducer(
  initialState,
  on(ProjectsActions.LoadProjects, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ProjectsActions.LoadProjectsSuccess, (state, { payload }) =>
    adapter.upsertMany(payload.projects || [], { ...state, isLoading: false }),
  ),
  on(ProjectsActions.LoadProjectsError, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(ProjectsActions.DeleteProjectSuccess, (state, { payload }) =>
    adapter.removeOne(payload, state),
  ),
  on(ProjectsActions.SetProjectLock, (state, { payload }) =>
    adapter.updateOne(
      { id: payload.id, changes: { is_locked: payload.isLocked } },
      state,
    ),
  ),
);

export function projectsReducer(
  state: ProjectsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
