import { createSelector } from '@ngrx/store';
import { FeasibilityStudyState } from '..';
import { TruckDimensions } from '../../shared/models/models';
import { adapter, TruckState } from './truck.reducer';

export namespace TruckSelectors {
  export const truckState = (state: FeasibilityStudyState) => state.truck;

  const { selectAll } = adapter.getSelectors();

  export const getTruckVariants = createSelector(truckState, selectAll);
  export const getTruckVariantsLoading = createSelector(
    truckState,
    (state: TruckState) => state.truckVariantsResultLoading,
  );
  export const getTruckVariantsLoadingError = createSelector(
    truckState,
    (state: TruckState) => state.truckVariantsResultLoadingError,
  );
  export const getSelectedTruck = createSelector(
    truckState,
    (state) => state.entities[state.selectedTruckId],
  );
  export const getTruckDetails = createSelector(
    truckState,
    (state) => state.truckDetails,
  );
  export const getTruckDetailsLoading = createSelector(
    truckState,
    (state) => state.truckDetailsLoading,
  );
  export const getTruckDefaultDimensions = createSelector(
    getTruckDetails,
    (details) =>
      <TruckDimensions>{
        chassisLoaded: details?.chassis_loaded,
        chassisUnloaded: details?.chassis_unloaded,
        fenderSeries: details?.fender_series,
        lDimension: details?.rear_overhang,
        rDimension: details?.r,
        suspensionInstallSpace: details?.suspension_install_space,
        wheelbase1: details?.wheelbase,
        wheelbase2: details?.wheelbase2 || 0,
        frameWidth: details?.e,
        wheelWidth: details?.track_width,
      },
  );
}
