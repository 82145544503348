import { Action, createReducer, on } from '@ngrx/store';
import { UserActions } from './user.actions';

export interface UserState {
  isAuthenticated: boolean;
  isAdmin: boolean;
}

const initialState: UserState = {
  isAuthenticated: false,
  isAdmin: false,
};

const reducer = createReducer(
  initialState,
  on(UserActions.setUserAuthenticated, (state, { payload }) => ({
    ...state,
    isAuthenticated: payload,
  })),

  on(UserActions.setUserAdmin, (state, { payload }) => ({
    ...state,
    isAdmin: payload,
  })),
);

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action);
}
