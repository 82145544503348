import { Injectable, inject } from '@angular/core';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { take, tap } from 'rxjs/operators';
import { SnackbarActions } from './snackbar.actions';

@Injectable()
export class SnackbarEffects {
  private _actions$: Actions = inject(Actions);

  showInfoSnackbar$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SnackbarActions.ShowInfoSnackbar),
        tap(({ messageKey }) =>
          this._snackbar.queue(this._translateService.instant(messageKey), {
            type: DsSnackbarType.Info,
          }),
        ),
      ),
    { dispatch: false },
  );

  showWarningSnackbar$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SnackbarActions.ShowWarningSnackbar),
        tap(({ messageKey }) =>
          this._snackbar.queue(this._translateService.instant(messageKey), {
            type: DsSnackbarType.Warning,
          }),
        ),
      ),
    { dispatch: false },
  );

  showSuccessSnackbar$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SnackbarActions.ShowSuccessSnackbar),
        tap(({ messageKey }) =>
          this._snackbar.queue(this._translateService.instant(messageKey), {
            type: DsSnackbarType.Success,
          }),
        ),
      ),
    { dispatch: false },
  );

  showErrorSnackbar$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SnackbarActions.ShowErrorSnackbar),
        tap(({ messageKey }) =>
          this._snackbar.queue(this._translateService.instant(messageKey), {
            type: DsSnackbarType.Error,
          }),
        ),
      ),
    { dispatch: false },
  );

  showErrorReloadSnackbar$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SnackbarActions.ShowErrorReloadSnackbar),
        tap(({ messageKey }) =>
          this._snackbar
            .queue(this._translateService.instant(messageKey), {
              type: DsSnackbarType.Error,
              action: this._translateService.instant(
                'feasibility_study.general.reload',
              ),
            })
            .pipe(take(1))
            .subscribe((ref) => {
              ref
                .onAction()
                .pipe(take(1))
                .subscribe(() => {
                  window.location.reload();
                });
            }),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private _snackbar: DsSnackbarService,
    private _translateService: TranslateService,
  ) {}
}
