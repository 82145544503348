import { Action, createReducer, on } from '@ngrx/store';
import { TailLift, TailLifts } from '../../shared/generated';
import { CurrentProjectActions } from '../projects';
import { TailLiftActions } from './tail-lift.actions';

export interface TailLiftState {
  tailLliftsResult: TailLift[] | null;
  tailLliftsResultLoading: boolean;
  tailLliftsResultLoadingError: boolean;
  selectedTailLiftId: number;
}

const initialState: TailLiftState = {
  tailLliftsResult: null,
  tailLliftsResultLoading: false,
  tailLliftsResultLoadingError: false,
  selectedTailLiftId: 0,
};

export const reducer = createReducer(
  initialState,

  on(TailLiftActions.LoadTailLifts, (state) => ({
    ...state,
    tailLliftsResult: null,
    tailLliftsResultLoading: true,
    tailLliftsResultLoadingError: false,
  })),

  on(TailLiftActions.LoadTailLiftsSuccess, (state, { payload }) => {
    const tailLlifts: TailLifts = payload.tailLlifts;
    return {
      ...state,
      tailLliftsResult: tailLlifts.tail_lifts.map((t) => ({
        ...t,
        name: t.name?.trim(),
        series: t.series?.trim(),
      })),
      tailLliftsResultLoading: false,
    };
  }),

  on(TailLiftActions.LoadTailLiftsError, (state) => ({
    ...state,
    tailLliftsResult: null,
    tailLliftsResultLoading: false,
    tailLliftsResultLoadingError: true,
  })),
  on(TailLiftActions.SelectTailLiftId, (state, { id }) => ({
    ...state,
    selectedTailLiftId: id,
  })),
  on(TailLiftActions.Reset, () => ({
    ...initialState,
  })),
  on(CurrentProjectActions.LoadProjectSuccess, (state, { payload }) => ({
    ...state,
    selectedTailLiftId: payload.tail_lift?.id || 0,
  })),
);

export function tailLliftReducer(
  state: TailLiftState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
