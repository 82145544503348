import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@features/auth';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard {
  private userService: UserService = inject(UserService);
  constructor(public router: Router) {}

  /**
   * route to appropriate route based on user log in
   */
  canActivate(): Observable<boolean> {
    return this.checkLoggedIn().pipe(
      map((loggedIn) => {
        if (loggedIn) this.router.navigate(['/projects']);
        else this.router.navigate(['/project']);
        return true;
      }),
    );
  }
  /**
   * check if user is logged in
   */
  private checkLoggedIn(): Observable<boolean> {
    return this.userService.isAuthorized$.pipe(take(1));
  }
}
