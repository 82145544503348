import { Action, createReducer, on } from '@ngrx/store';
import { Project } from '../../../shared/generated';
import { CurrentProjectActions } from './current-project.actions';

export interface CurrentProjectState {
  name: string | null;
  commission: string | null;
  isRequestPending: boolean;
  isProjectSaving: boolean;
  isProjectLoading: boolean;
  autoStudyResult: Project | null;
  isLocked: boolean;
  imageUrl: string | null;
  pdfUrl: string | null;
  dxfUrl: string | null;
  errors: string[];
  id: number;
}

const initialState: CurrentProjectState = {
  name: null,
  commission: null,
  isRequestPending: false,
  autoStudyResult: null,
  isProjectSaving: false,
  isProjectLoading: false,
  isLocked: false,
  imageUrl: null,
  pdfUrl: null,
  dxfUrl: null,
  errors: [],
  id: 0,
};

const reducer = createReducer(
  initialState,
  on(CurrentProjectActions.SetName, (state, { payload }) => ({
    ...state,
    name: payload,
  })),
  on(CurrentProjectActions.SetCommission, (state, { payload }) => ({
    ...state,
    commission: payload,
  })),
  on(CurrentProjectActions.SendRequest, (state) => ({
    ...state,
    isRequestPending: true,
  })),
  on(CurrentProjectActions.SendRequestSuccess, (state, { payload }) => ({
    ...state,
    isRequestPending: false,
    autoStudyResult: payload,
    imageUrl: payload.image_url || null,
    dxfUrl: payload.dxf_url || null,
    pdfUrl: payload.pdf_url || null,
    errors: payload.errors || [],
    id: payload.id,
  })),
  on(CurrentProjectActions.SendRequestError, (state) => ({
    ...state,
    isRequestPending: false,
    autoStudyResult: null,
  })),
  on(CurrentProjectActions.SaveProject, (state) => ({
    ...state,
    isProjectSaving: true,
  })),
  on(CurrentProjectActions.SaveProjectSuccess, (state, { id, isLocked }) => ({
    ...state,
    id: id,
    isLocked: isLocked,
    isProjectSaving: false,
  })),
  on(CurrentProjectActions.SaveProjectError, (state) => ({
    ...state,
    isProjectSaving: false,
  })),
  on(CurrentProjectActions.LoadProject, (state) => ({
    ...state,
    isProjectLoading: true,
  })),
  on(CurrentProjectActions.LoadProjectSuccess, (state, { payload }) => ({
    ...state,
    isProjectLoading: false,
    id: payload.id,
    commission: payload.commission || null,
    name: payload.name || null,
    isLocked: payload.is_locked,
    imageUrl: payload.image_url || null,
    dxfUrl: payload.dxf_url || null,
    pdfUrl: payload.pdf_url || null,
    errors: payload.errors || [],
  })),
  on(CurrentProjectActions.LoadProjectError, (state) => ({
    ...state,
    isProjectLoading: false,
  })),
  on(CurrentProjectActions.Reset, () => ({
    ...initialState,
  })),
);

export function currentProjectReducer(
  state: CurrentProjectState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
