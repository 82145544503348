import { createAction, props } from '@ngrx/store';

export namespace UserActions {
  export const checkUser = createAction('[USER] CHECK_USER');

  export const setUserAuthenticated = createAction(
    '[USER] SET_USER_AUTHENTICATED',
    props<{ payload: boolean }>(),
  );

  export const setUserAdmin = createAction(
    '[USER] SET_USER_ADMIN',
    props<{ payload: boolean }>(),
  );
}
