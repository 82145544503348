import { createAction, props } from '@ngrx/store';

export namespace SnackbarActions {
  export const ShowInfoSnackbar = createAction(
    '[SNACKBAR] SHOW_INFO_SNACKBAR',
    props<{ messageKey: string }>(),
  );

  export const ShowWarningSnackbar = createAction(
    '[SNACKBAR] SHOW_WARNING_SNACKBAR',
    props<{ messageKey: string }>(),
  );

  export const ShowSuccessSnackbar = createAction(
    '[SNACKBAR] SHOW_SUCCESS_SNACKBAR',
    props<{ messageKey: string }>(),
  );

  export const ShowErrorSnackbar = createAction(
    '[SNACKBAR] SHOW_ERROR_SNACKBAR',
    props<{ messageKey: string }>(),
  );

  export const ShowErrorReloadSnackbar = createAction(
    '[SNACKBAR] SHOW_ERROR_RELOAD_SNACKBAR',
    props<{ messageKey: string }>(),
  );
}
