import { Injectable, inject } from '@angular/core';
import { UserService } from '@features/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { TrucksService } from '../../shared/generated';
import { SettingsActions } from '../settings';
import { TruckActions } from './truck.actions';

@Injectable()
export class TruckEffects {
  private actions$: Actions = inject(Actions);
  private userService: UserService = inject(UserService);

  loadTruckVariants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TruckActions.LoadTruckVariants),
      switchMap(() =>
        this.userService.isAuthorized$.pipe(
          take(1),
          switchMap((isAuthorized) =>
            (isAuthorized
              ? this.truckService.getTrucks()
              : this.truckService.publicGetTrucks()
            ).pipe(
              map((truckVariants) =>
                TruckActions.LoadTruckVariantsSuccess({
                  payload: truckVariants.trucks,
                }),
              ),
              catchError(() => of(TruckActions.LoadTruckVariantsError())),
            ),
          ),
        ),
      ),
    ),
  );

  selectTruckId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TruckActions.SelectTruckId),
      map((a) => a.id),
      map((id) => TruckActions.LoadTruckDetails({ id: id })),
    ),
  );

  loadTruckDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TruckActions.LoadTruckDetails),
      withLatestFrom(this.userService.isAuthorized$),
      map(([a, isAuthorized]) => ({ id: a.id, isAuthorized })),
      switchMap((x) =>
        (x.isAuthorized
          ? this.truckService.getTruckDetails(x.id)
          : this.truckService.publicGetTruckDetails(x.id)
        ).pipe(
          mergeMap((details) => [
            TruckActions.LoadTruckDetailsSuccess({ payload: details }),
            SettingsActions.SetTruckAdaptionDimensions({ payload: details }),
            SettingsActions.SetbuildUpStart({
              payload: details.front_axle_to_box_body,
            }),
          ]),
          catchError(() => [TruckActions.LoadTruckDetailsError()]),
        ),
      ),
    ),
  );

  constructor(private truckService: TrucksService) {}
}
