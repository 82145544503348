/* eslint-disable max-lines */
import { Action, createReducer, on } from '@ngrx/store';
import { SettingsActions } from '.';
import {
  Bracket,
  TruckCouplingType,
  TruckSupportType,
} from '../../shared/generated';
import { Constants } from '../../shared/utils/constants';
import { CurrentProjectActions } from '../projects';
import { TruckActions } from '../truck';
import {
  calculateM,
  calculateStateByCDimensions,
  calculateStateByMDimension,
  calculateX,
  getArmLength,
} from './settings.reducer.helper';

export interface SettingsState {
  focusedDimension: string;
  bracket: Bracket;
  support: TruckSupportType;
  hasJawCoupling: boolean;
  coupling: TruckCouplingType;
  isGClass: boolean;
  suggestedArmLength: number;
  mDimension: number | null;
  dkDimension: number | null;
  dDimension: number | null;
  hDimensionLoaded: number | null;
  hDimensionUnLoaded: number | null;
  rDimension: number | null;
  c1Dimension: number | null;
  c2Dimension: number | null;
  c3Dimension: number | null;
  cDimension: number | null;
  nDimension: number | null;
  uDimension: number | null;
  vDimension: number | null;
  yDimension: number | null;
  dhDimension: number | null;
  xDimension: number | null;
  x1Dimension: number | null;
  y1Dimension: number | null;
  buildUpLength: number | null;
  buildUpWidth: number | null;
  buildUpStart: number | null; // should be required
  wheelbase1: number | null; // should be required
  wheelbase2: number | null; // should be required
  lDimension: number | null; // should be required
  maxJawCouplingWidth: number | null;
  suspensionInstallSpace: number | null;
  chassisLoaded: number | null;
  chassisUnloaded: number | null;
  fenderSeries: number | null;
  eDimension: number | null;
  wheelWidth: number | null;
  dimensionsLoading: boolean;
}

export const initialState: SettingsState = {
  focusedDimension: 'NONE',
  suggestedArmLength: 0,
  bracket: Bracket.Bolted,
  support: TruckSupportType.None,
  hasJawCoupling: false,
  coupling: TruckCouplingType.Undefined,
  isGClass: false,
  c1Dimension: null,
  c2Dimension: null,
  c3Dimension: null,
  cDimension: null,
  dDimension: null,
  dhDimension: Constants.DefaultDhDimension,
  dkDimension: null,
  hDimensionLoaded: null,
  hDimensionUnLoaded: null,
  mDimension: null,
  nDimension: null,
  rDimension: null,
  uDimension: 0,
  vDimension: 0,
  x1Dimension: 0,
  xDimension: 0,
  y1Dimension: null,
  yDimension: null,
  buildUpLength: null,
  buildUpStart: null,
  buildUpWidth: 0,
  lDimension: null,
  wheelbase1: null,
  wheelbase2: 0,
  chassisLoaded: null,
  chassisUnloaded: null,
  fenderSeries: null,
  suspensionInstallSpace: null,
  eDimension: null,
  wheelWidth: null,
  dimensionsLoading: false,
  maxJawCouplingWidth: null,
};

export const reducer = createReducer(
  initialState,

  on(SettingsActions.SetFocusedDimension, (state, { payload }) => ({
    ...state,
    focusedDimension: payload.dimension,
  })),

  on(SettingsActions.SetMDimension, (state, { payload }) => {
    const newState = {
      ...state,
      mDimension: payload,
    };
    return calculateStateByMDimension(newState);
  }),
  on(SettingsActions.SetDkDimension, (state, { payload }) => {
    const newState: SettingsState = {
      ...state,
      dkDimension: payload,
      mDimension: state.mDimension
        ? state.mDimension
        : state.fenderSeries
          ? state.fenderSeries + payload
          : state.mDimension,
      fenderSeries: state.mDimension
        ? state.mDimension - payload
        : state.fenderSeries,
    };

    return calculateStateByMDimension(newState);
  }),
  on(SettingsActions.SetDDimension, (state, { payload }) => {
    const newState: SettingsState = {
      ...state,
      dDimension: payload,
      mDimension: state.mDimension
        ? state.mDimension
        : state.suspensionInstallSpace
          ? state.suspensionInstallSpace + payload
          : state.mDimension,
      suspensionInstallSpace: state.mDimension
        ? state.mDimension - payload
        : state.suspensionInstallSpace,
    };

    return calculateStateByMDimension(newState);
  }),
  on(SettingsActions.SetHLoadedDimension, (state, { payload }) => ({
    ...state,
    hDimensionLoaded: payload,
    chassisLoaded:
      payload -
      (state.c1Dimension || 0) -
      (state.c2Dimension || 0) -
      (state.c3Dimension || 0),
  })),
  on(SettingsActions.SetHUnloadedDimension, (state, { payload }) => ({
    ...state,
    hDimensionUnLoaded: payload,
    suggestedArmLength: getArmLength(payload),
    chassisUnloaded:
      payload -
      (state.c1Dimension || 0) -
      (state.c2Dimension || 0) -
      (state.c3Dimension || 0),
  })),
  on(SettingsActions.SetRDimension, (state, { payload }) => {
    const newState: SettingsState = {
      ...state,
      rDimension: payload,
    };

    return calculateStateByCDimensions(newState);
  }),
  on(SettingsActions.SetC1Dimension, (state, { payload }) => {
    const newState: SettingsState = {
      ...state,
      c1Dimension: payload,
    };

    return calculateStateByCDimensions(newState);
  }),
  on(SettingsActions.SetC2Dimension, (state, { payload }) => {
    const newState: SettingsState = {
      ...state,
      c2Dimension: payload,
    };

    return calculateStateByCDimensions(newState);
  }),
  on(SettingsActions.SetC3Dimension, (state, { payload }) => {
    const newState: SettingsState = {
      ...state,
      c3Dimension: payload,
    };

    return calculateStateByCDimensions(newState);
  }),
  on(SettingsActions.SetNDimension, (state, { payload }) => {
    const newState: SettingsState = {
      ...state,
      nDimension: payload,
      xDimension: calculateX(state),
      mDimension: state.lDimension
        ? state.lDimension + payload
        : state.mDimension,
      lDimension:
        state.mDimension && !state.lDimension
          ? state.mDimension - payload
          : state.lDimension,
    };

    return calculateStateByMDimension(newState);
  }),
  on(SettingsActions.SetUDimension, (state, { payload }) => ({
    ...state,
    uDimension: payload,
  })),
  on(SettingsActions.SetVDimension, (state, { payload }) => ({
    ...state,
    vDimension: payload,
  })),
  on(SettingsActions.SetYDimension, (state, { payload }) => ({
    ...state,
    yDimension: payload,
  })),
  on(SettingsActions.SetXDimension, (state, { payload }) => ({
    ...state,
    xDimension: payload,
  })),
  on(SettingsActions.SetDhDimension, (state, { payload }) => ({
    ...state,
    dhDimension: payload,
  })),
  on(SettingsActions.SetY1Dimension, (state, { payload }) => ({
    ...state,
    y1Dimension: payload,
  })),
  on(SettingsActions.SetX1Dimension, (state, { payload }) => ({
    ...state,
    x1Dimension: payload,
  })),
  on(SettingsActions.SetBuildUpLength, (state, { payload }) => {
    let newState: SettingsState = {
      ...state,
      buildUpLength: payload,
    };

    newState = {
      ...newState,
      mDimension: calculateM(newState),
    };

    return calculateStateByMDimension(newState);
  }),
  on(SettingsActions.SetBuildUpWidth, (state, { payload }) => ({
    ...state,
    buildUpWidth: payload,
  })),
  on(SettingsActions.SetbuildUpStart, (state, { payload }) => {
    let newState: SettingsState = {
      ...state,
      buildUpStart: payload,
    };

    newState = {
      ...newState,
      mDimension: calculateM(newState),
    };

    return calculateStateByMDimension(newState);
  }),
  on(SettingsActions.SetWheelbase1, (state, { payload }) => {
    let newState: SettingsState = {
      ...state,
      wheelbase1: payload,
    };

    newState = {
      ...newState,
      mDimension: calculateM(newState),
    };

    return calculateStateByMDimension(newState);
  }),
  on(SettingsActions.SetWheelbase2, (state, { payload }) => {
    let newState: SettingsState = {
      ...state,
      wheelbase2: payload,
    };

    newState = {
      ...newState,
      mDimension: calculateM(newState),
    };

    return calculateStateByMDimension(newState);
  }),
  on(SettingsActions.SetLDimension, (state, { payload }) => {
    const newState: SettingsState = {
      ...state,
      lDimension: payload,
      nDimension: state.mDimension
        ? state.mDimension - payload
        : state.nDimension,
      mDimension:
        state.nDimension && !state.mDimension
          ? state.nDimension + payload
          : state.mDimension,
    };

    return calculateStateByMDimension(newState);
  }),
  on(SettingsActions.SetFenderSeries, (state, { payload }) => ({
    ...state,
    fenderSeries: payload,
    dkDimension: state.mDimension
      ? state.mDimension - payload
      : state.dkDimension,
  })),
  on(SettingsActions.SetSuspensionInstallSpace, (state, { payload }) => ({
    ...state,
    suspensionInstallSpace: payload,
    dDimension: state.mDimension
      ? state.mDimension - payload
      : state.dDimension,
  })),
  on(SettingsActions.SetChassisLoaded, (state, { payload }) => ({
    ...state,
    chassisLoaded: payload,
    hDimensionLoaded:
      payload +
      (state.c1Dimension || 0) +
      (state.c2Dimension || 0) +
      (state.c3Dimension || 0),
  })),
  on(SettingsActions.SetChassisUnlaoded, (state, { payload }) => {
    const hUnloaded =
      payload +
      (state.c1Dimension || 0) +
      (state.c2Dimension || 0) +
      (state.c3Dimension || 0);

    return {
      ...state,
      chassisUnloaded: payload,
      hDimensionUnLoaded: hUnloaded,
      suggestedArmLength: getArmLength(hUnloaded),
    };
  }),

  on(SettingsActions.Reset, () => ({
    ...initialState,
  })),
  on(SettingsActions.SetBracket, (state, { payload }) => ({
    ...state,
    bracket: payload,
  })),
  on(SettingsActions.SetSupport, (state, { payload }) => ({
    ...state,
    support: payload,
  })),
  on(SettingsActions.SetHasJawCoupling, (state, { payload }) => ({
    ...state,
    hasJawCoupling: payload,
    // if jaw coupling is deselected also set reset dh dimension, otherwise use current value
    dhDimension: !payload ? Constants.DefaultDhDimension : state.dhDimension,
  })),
  on(SettingsActions.SetCoupling, (state, { payload }) => ({
    ...state,
    coupling: payload,
  })),
  on(SettingsActions.SetIsGClass, (state, { payload }) => ({
    ...state,
    isGClass: payload,
  })),
  on(SettingsActions.SetEDimension, (state, { payload }) => ({
    ...state,
    eDimension: payload,
  })),
  on(SettingsActions.SetWheelWidth, (state, { payload }) => ({
    ...state,
    wheelWidth: payload,
  })),
  on(CurrentProjectActions.LoadProjectSuccess, (state, { payload }) => ({
    ...state,
    c1Dimension: mapUndefined(payload.dimension_c1),
    c3Dimension: mapUndefined(payload.dimension_c3),
    c2Dimension: mapUndefined(payload.dimension_c2),
    dhDimension: mapUndefined(payload.dimension_dh),
    dkDimension: mapUndefined(payload.dimension_dk),
    hDimensionLoaded: mapUndefined(payload.dimension_hl),
    hDimensionUnLoaded: mapUndefined(payload.dimension_hu),
    x1Dimension: mapUndefined(payload.dimension_x1),
    y1Dimension: mapUndefined(payload.dimension_y1),
    xDimension: mapUndefined(payload.dimension_x),
    yDimension: mapUndefined(payload.dimension_y),
    cDimension: mapUndefined(payload.dimension_c),
    dDimension: mapUndefined(payload.dimension_d),
    mDimension: mapUndefined(payload.dimension_m),
    nDimension: mapUndefined(payload.dimension_n),
    rDimension: payload.truck_adaptions
      ? mapUndefined(payload.truck_adaptions.r)
      : mapUndefined(payload.dimension_r),
    uDimension: mapUndefined(payload.dimension_u),
    vDimension: mapUndefined(payload.dimension_v),
    buildUpWidth: mapUndefined(payload.buildup_width),
    bracket: payload.bracket_type || Bracket.Bolted,
    coupling: payload.coupling_type,
    isGClass: payload.g_class,
    hasJawCoupling: payload.jaw_coupling,
    support: payload.support_type,
    chassisLoaded: mapUndefined(payload.truck_adaptions?.chassis_loaded),
    chassisUnloaded: mapUndefined(payload.truck_adaptions?.chassis_unloaded),
    eDimension: mapUndefined(payload.truck_adaptions?.e),
    lDimension: mapUndefined(payload.truck_adaptions?.rear_overhang),
    fenderSeries: mapUndefined(payload.truck_adaptions?.fender_series),
    suspensionInstallSpace: mapUndefined(
      payload.truck_adaptions?.suspension_install_space,
    ),
    wheelWidth: mapUndefined(payload.truck_adaptions?.track_width),
    wheelbase1: mapUndefined(payload.truck_adaptions?.wheelbase),
    wheelbase2: mapUndefined(payload.truck_adaptions?.wheelbase2),
  })),

  on(TruckActions.LoadTruckDetails, (state) => ({
    ...state,
    dimensionsLoading: true,
  })),

  on(TruckActions.LoadTruckDetailsSuccess, (state) => ({
    ...state,
    dimensionsLoading: false,
  })),

  on(TruckActions.LoadTruckDetailsError, (state) => ({
    ...state,
    dimensionsLoading: false,
  })),
  on(SettingsActions.SetMaxJawCouplingWidth, (state, { payload }) => ({
    ...state,
    maxJawCouplingWidth: payload,
    xDimension: calculateX(state),
  })),
);

function mapUndefined<T>(value?: T | null): T | null {
  if (value === undefined) return null;
  return value;
}

export function settingsReducer(
  state: SettingsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
